body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fafafa;
}

.MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #640032;
}

.MuiAppBar-positionSticky {
    top: 0;
    left: auto;
    right: 0;
    position: sticky;
}

.MuiAppBar-root {
    width: 100%;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
}

.MuiToolbar-root .MuiToolbar-regular .MuiToolbar-gutters {
    display: flex;
    justify-content: space-between;
}